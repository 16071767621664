export default {
  data () {
    return {
      list: [
        {
          id: 10086111,
          url: 'https://www.baidu.com',
          totalNum: 10,
          residualNum: 0,
          delayed: 2000,
          status: true// 状态
        }
      ],
      message: '',
      showDialog: false, // 是否显示弹窗
      tableLoad: false, // 表格加载
      scene: 0, // 场景: 0表示弹框为添加，1表示弹框为修改
      // 表单信息
      form: {
        note: '',
        files_array: [],
        status: ''
      },
      // 表单验证规则
      rules: {
        files_array: [{
          required: true,
          message: '请上传文件'
        }]
      },

      // 分页信息[前端控制单页数量]
      pageData: {
        page: 1,
        limit: 10,
        total: 0,
        list: [],
        select: {
          status: {}
        }
      },

      // 表单筛选
      searchForm: {
        note: null,
        status: null,
        create_time_array: []
      }
    }
  },
  // 初始化函数
  created: async function () {
  },
  methods: {
    Click: function () {
      const workers = []
      this.list.forEach((item, index) => {
        const worker = new Worker('Worker.js')
        worker.onmessage = (event) => {
          console.log(`Worker ${index} - ${event.data}`)
        }

        worker.postMessage({
          id: item.id,
          url: item.url,
          totalNum: item.totalNum,
          delayed: item.delayed,
          status: item.status
        })
        workers.push(worker)
      })
    }
  }
}
